import React, {useState, useEffect, useCallback} from 'react';
import './FamilyOverviewComponent.scss';
import axios from "axios";
import {Button} from "primereact/button";

import familyIcon from '../../assets/img/family-icon.png';
import {Dialog} from "primereact/dialog";
import {Image} from "primereact/image";
import {Toolbar} from "primereact/toolbar";
import {InputText} from "primereact/inputtext";
import {Card} from "primereact/card";
import {confirmPopup} from "primereact/confirmpopup";
import {useToast} from "../../ToastProvider";

function FamilyOverviewComponent() {
    const showToast = useToast();

    const [families, setFamilies] = useState([]);
    const [showFormModal, setShowFormModal] = useState(false);
    const [familyName, setFamilyName] = useState('');

    const [editFamily, setEditFamily] = useState(null);
    const [showEditModal, setShowEditModal] = useState(false);

    const fetchFamilies = useCallback(async () => {
        try {
            const response = await axios.get('/users/me/families');
            setFamilies(response.data);
        } catch (error) {
            showToast({severity: 'error', summary: 'Fehler', detail: 'Fehler beim Laden der Familien'});
        }
    }, [showToast]);

    useEffect(() => {
        fetchFamilies();
    }, [fetchFamilies]);

    const handleAddFamily = async () => {
        try {
            const response = await axios.post('/users/me/families', {name: familyName});
            if (response.data) {
                setFamilies([...families, {name: familyName, id: response.data.familyId}]);
                setFamilyName('');
                setShowFormModal(false);
                showToast({severity: 'success', summary: 'Erfolg', detail: 'Familie erfolgreich erstellt'});
            }
        } catch (error) {
            showToast({severity: 'error', summary: 'Fehler', detail: 'Fehler beim Erstellen der Familie'});
        }
    };

    const handleEditFamily = async () => {
        try {
            const response = await axios.put(`/families/${editFamily.id}`, editFamily);
            if (response.data) {
                setFamilies(families.map(family => family.id === editFamily.id ? editFamily : family));
                setShowEditModal(false);
                showToast({severity: 'success', summary: 'Erfolg', detail: 'Familie erfolgreich bearbeitet'});
            }
        } catch (error) {
            showToast({severity: 'error', summary: 'Fehler', detail: 'Fehler beim Bearbeiten der Familie'});
        }
    };

    const dialogFooterContent = (
        <div>
            <Button className={"btn"} onClick={handleAddFamily}>Hinzufügen</Button>
            <Button className={"btn"} onClick={() => setShowFormModal(false)}>Abbrechen</Button>
        </div>
    );
    const editDialogFooterContent = (
        <div>
            <Button className={"btn"} onClick={handleEditFamily}>Speichern</Button>
            <Button className={"btn"} onClick={() => setShowEditModal(false)}>Abbrechen</Button>
        </div>
    );

    function gotoFamily(id) {
        window.location.href = `/families/${id}`;
    }

    const toolbar = () => {
        return (
            <React.Fragment>
                <Button icon="pi pi-plus" className="p-button-success p-mr-2" rounded
                        label={"Familie"}
                        onClick={() => setShowFormModal(true)}/>
            </React.Fragment>
        )
    }

    const acceptDelete = (id) => {
        // if status 400, show error message
        axios.delete(`/families/${id}`).then((response) => {
            if (response.data.success === false) {
                showToast({severity: 'error', summary: 'Fehler', detail: response.data.message});
            } else {
                showToast({severity: 'success', summary: 'Erfolg', detail: 'Familie erfolgreich gelöscht'});
            }

            if (response.status === 200) {
                fetchFamilies();
            }
        });

        return true;
    };

    const rejectDelete = (id) => {
        return true;
    };

    const confirmDeleteFamily = (event, id) => {
        confirmPopup({
            target: event.currentTarget,
            message: 'Wirklich löschen?',
            icon: 'pi pi-exclamation-triangle',
            acceptLabel: 'Ja',
            rejectLabel: 'Nein',
            accept: () => acceptDelete(id),
            reject: () => rejectDelete(id)
        });
    };

    const openEditModal = (family) => {
        setEditFamily(family);
        setShowEditModal(true);
    };

    return (
        <div>
            <div className="card justify-content-center p-4">
                <Toolbar left={toolbar}/>
                <Dialog header="Familie" visible={showFormModal}
                        onHide={() => setShowFormModal(false)} footer={dialogFooterContent}
                        className={"w-full md:w-30rem"}>
                    <InputText
                        value={familyName}
                        className={"w-full"}
                        onChange={e => setFamilyName(e.target.value)}
                        onKeyDown={e => {
                            if (e.key === 'Enter' && familyName !== '') {
                                handleAddFamily();
                            }
                        }}
                        placeholder="z.B. Weber"
                    />
                </Dialog>

                <div className="flex flex-wrap gap-5 justify-content-start">
                    {families && families.map(family => (
                        <Card key={family.id}
                              className="w-17rem max-w-21rem shadow-4 p-0"
                        >
                            <h3 className="p-2 pt-0 cursor-pointer"
                                onClick={() => gotoFamily(family.id)}>{family.name}</h3>
                            <div className="p-2">
                                <Image src={familyIcon} alt="Family Icon" width={"120"}/>
                            </div>
                            <div className="flex flex-row gap-2 justify-content-center">
                                <Button size={"small"} severity="info" icon="pi pi-eye" alt={"Ansehen"}
                                        title={"Ansehen"}
                                        onClick={() => gotoFamily(family.id)} rounded/>
                                <Button size={"small"} severity="warning" icon="pi pi-pencil"
                                        alt={"Bearbeiten"}
                                        title={"Bearbeiten"} rounded
                                        onClick={() => openEditModal(family)}
                                />
                                <Button size={"small"} severity="danger" icon="pi pi-trash" alt={"Löschen"}
                                        title={"Löschen"} rounded
                                        onClick={(event) => confirmDeleteFamily(event, family.id)}
                                />
                            </div>
                        </Card>
                    ))}
                    {(families.length === 0) && (
                        <div className="flex flex-col justify-content-center items-center">
                            <h3 className="text-center">Keine Familien vorhanden</h3>
                            <p className="text-center">Erstelle eine neue Familie, um mit dem Erstellen von
                                Wunschsternen
                                zu beginnen.</p>
                        </div>
                    )}
                    <Dialog header="Familie bearbeiten" visible={showEditModal}
                            onHide={() => setShowEditModal(false)} footer={editDialogFooterContent}
                            className={"w-full md:w-30rem"}>
                        <InputText
                            value={editFamily ? editFamily.name : ''}
                            className={"w-full"}
                            onChange={e => setEditFamily({...editFamily, name: e.target.value})}
                            placeholder="z.B. Schmidt"
                        />
                    </Dialog>
                </div>
            </div>
        </div>
    );
}

export default FamilyOverviewComponent;