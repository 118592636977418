import React, { useState } from 'react';

const AuthContext = React.createContext();

export const AuthProvider = (props) => {
    const [isAuth, setIsAuth] = useState(!!localStorage.getItem('jwtToken'));

    const login = (token) => {
        localStorage.setItem('jwtToken', token);
        setIsAuth(true);
    };

    const logout = () => {
        localStorage.removeItem('jwtToken');
        setIsAuth(false);
    };

    return (
        <AuthContext.Provider value={{ isAuth, login, logout }}>
            {props.children}
        </AuthContext.Provider>
    );
}

export default AuthContext;