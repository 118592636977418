import React from 'react';
import './FooterComponent.scss';

function FooterComponent() {
    return (
        <footer className="App-footer">
            <div className="wreath"></div>
            <p><a href={"https://bastianehrenberg.de/"}>Made with ❤️ by Bastian Ehrenberg</a></p>
            <div className="version">
                <p>v 0.3</p>
            </div>
        </footer>
    );
}

export default FooterComponent;