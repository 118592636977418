import React from 'react';
import './FamiliesComponent.scss';

function FamiliesComponent({families}) {
    return (
        <div className={"box w-full md:w-20rem"}>

            <div className="title">
                <i className="pi pi-heart mr-2"></i>
                Deine Familien
            </div>
            <div className="content">
                <div className="flex flex-wrap">
                    {families && families.map((family, index) => (
                        <a className={"w-full shadow-3 border-round-2xl p-3 mb-2 font-bold"}
                           key={family.id}
                           href={"/families/" + family.id}>
                            {family.name}
                        </a>
                    ))}

                    {families && families.length === 0 && (
                        <p className={"text-center w-full"}>
                            Du bist noch in keiner Familie.
                        </p>
                    )}
                </div>
            </div>
        </div>
    );
}

export default FamiliesComponent;