import {useContext, useEffect} from 'react';
import axios from 'axios';
import AuthContext from './authContext';

export const useAxiosInterceptor = () => {
    const {logout} = useContext(AuthContext);

    useEffect(() => {
        axios.defaults.baseURL = process.env.REACT_APP_SERVER_URL;
        axios.defaults.timeout = 5000;
        axios.defaults.headers.common['Authorization'] = '';

        const requestInterceptor = axios.interceptors.request.use(config => {
            const token = localStorage.getItem('jwtToken');

            if (!token) {
                logout();
                return Promise.reject('No token found');
            }

            config.headers['Authorization'] = `Bearer ${token}`;

            return config;
        }, error => {
            return Promise.reject(error);
        });

        const responseInterceptor = axios.interceptors.response.use(response => {
            return response;
        }, error => {
            if (error.response && error.response.status === 401) {
                logout();
            }

            return Promise.reject(error);
        });

        // Bei der Bereinigung die Interceptors entfernen
        return () => {
            axios.interceptors.request.eject(requestInterceptor);
            axios.interceptors.response.eject(responseInterceptor);
        };
    }, [logout]);
};