import React, {useContext, useState} from 'react';
import './HeaderComponent.scss';
import logo from '../../assets/img/logo.png';
import AuthContext from "../../utils/authContext";
import {Button} from "primereact/button";
import {Sidebar} from "primereact/sidebar";

function HeaderComponent() {
    const {isAuth, logout} = useContext(AuthContext);
    const [visible, setVisible] = useState(false);

    const isPage = (page) => {
        const path = window.location.pathname.split('/')[1];
        return path === page ? 'active' : '';
    };
    return (
        <header className="App-header">
            <div className="content flex align-items-center gap-4">
                {isAuth && (
                    <Button icon="pi pi-bars" severity="info" onClick={() => setVisible(true)}
                            className="md:hidden border-circle bg-black-alpha-50"/>
                )}
                <img src={logo} className="App-logo" alt="logo"/>
                <a href={"/home"}><h1>WunschStern</h1></a>

                {isAuth && (
                    <>
                        <nav className="App-nav hidden md:block">
                            <ul>
                                <li>
                                    <a className={isPage('dashboard') ?? 'active'} href="/dashboard">
                                        <i className="pi pi-home"></i> Dashboard
                                    </a>
                                </li>
                                <li>
                                    <a className={isPage('wishlist') ?? 'active'} href="/wishlist">
                                        <i className="pi pi-list"></i> Wunschzettel
                                    </a>
                                </li>
                                <li>
                                    <a className={isPage('families') ?? 'active'} href="/families">
                                        <i className="pi pi-users"></i> Familien
                                    </a>
                                </li>
                                <li>
                                    <a href="/" onClick={() => logout()}>
                                        <i className="pi pi-key"></i> Logout
                                    </a>
                                </li>
                            </ul>
                        </nav>

                        <Sidebar visible={visible} onHide={() => setVisible(false)} position="left">
                            <nav className="">
                                <ul>
                                    <li>
                                        <a className={isPage('dashboard') ?? 'active'} href="/dashboard" onClick={() => setVisible(false)}>
                                            <i className="pi pi-home"></i> Dashboard
                                        </a>
                                    </li>
                                    <li>
                                        <a className={isPage('wishlist') ?? 'active'} href="/wishlist" onClick={() => setVisible(false)}>
                                            <i className="pi pi-list"></i> Wunschzettel
                                        </a>
                                    </li>
                                    <li>
                                        <a className={isPage('families') ?? 'active'} href="/families" onClick={() => setVisible(false)}>
                                            <i className="pi pi-users"></i> Familien
                                        </a>
                                    </li>
                                    <li>
                                        <a href="/" onClick={() => {
                                            setVisible(false);
                                            logout();
                                        }}><i className="pi pi-key"></i> Logout
                                        </a>
                                    </li>
                                </ul>
                            </nav>
                        </Sidebar>
                    </>
                )}
            </div>
            {/*<div className="wave">*/}
            {/*    <svg viewBox="0 0 500 150" preserveAspectRatio="none">*/}
            {/*        <path d="M0.00,92.27 C216.83,192.92 304.30,8.39 500.00,109.03 L500.00,0.00 L0.00,0.00 Z"></path>*/}
            {/*    </svg>*/}
            {/*</div>*/}
        </header>
    )
        ;
}

export default HeaderComponent;