import {Outlet, useNavigate} from "react-router-dom";
import {useContext, useEffect} from "react";
import AuthContext from "./authContext";

function PrivateRoute() {
    const navigate = useNavigate();
    const { isAuth } = useContext(AuthContext);

    useEffect(() => {
        if (!isAuth) {
            navigate('/home');
        }
    }, [isAuth, navigate]);

    return <Outlet />;
}

export default PrivateRoute;