import React from 'react';
import './DashboardComponent.scss';

import FamiliesComponent from "./boxes/Families/FamiliesComponent";
import axios from "axios";
import FamilyInvitesComponent from "./boxes/FamilyInvites/FamilyInvitesComponent";
import GiftInvitesComponent from "./boxes/GiftInvites/GiftInvitesComponent";
import {Button} from "primereact/button";

function DashboardComponent() {
    const [families, setFamilies] = React.useState(null);
    const [invitations, setInvitations] = React.useState(null);
    const [randomMessage, setRandomMessage] = React.useState('');

    const createSnowflakes = () => {
        const snowflakes = [];
        for (let i = 0; i < 50; i++) { // Anzahl der Schneeflocken
            const style = {
                left: Math.random() * 100 + '%',
                top: '-20%',
                animationDelay: Math.random() * 3 + 's',
                animationDuration: Math.random() * 5 + 5 + 's',
                fontSize: Math.random() * 20 + 4 + 'px',
            };
            snowflakes.push(<div key={i} className="snowflake" style={style}>❄</div>);
        }
        return snowflakes;
    };

    React.useEffect(() => {
        const getFamilies = async () => {
            try {
                const response = await axios.get('/families');
                setFamilies(response.data.data);
            } catch (error) {
                console.error("Fehler beim Abrufen der Familien:", error);
            }
        };

        getFamilies();

        const getInvitations = async () => {
            try {
                const response = await axios.get('/invitations');
                setInvitations(response.data.data);
            } catch (error) {
                console.error("Fehler beim Abrufen der Einladungen:", error);
            }
        }

        getInvitations();

        const messages = [
            "Hast du heute schon gelächelt?",
            "Vergiss nicht, eine Pause zu machen!",
            "Du bist großartig!",
            "Ich bin nicht faul, ich bin im Energiesparmodus.",
            "Jeder Tag ist ein neuer Anfang!",
            "Lächle, es ist ansteckend!",
            "Vergiss nicht, dein Rentier zu parken!",
            "Zähl Schneeflocken – aber nur bis 10.000!",
            "Wunschzettel 2.0 – weil nicht jeder einen Kamin für den Weihnachtsmann hat.",
            "Das Herz eines Helden kennt keine Grenzen!",
            "Kalorien sind kleine Tierchen, die nachts die Kleidung enger nähen.",
            "Heute im Angebot: Digitale Lebkuchen!",
            "Falls du dich verläufst, folge dem Stern oder GPS.",
            "Früher haben wir Wunschzettel per Postkutsche geschickt. Jetzt gibt's Apps dafür. Was für eine verrückte Welt!",
            "Lächle, Santa beobachtet dich!",
            "Dein Lächeln leuchtet heller als jede Weihnachtsbeleuchtung!",
            "Früher war ich unentschlossen, jetzt bin ich mir da nicht mehr so sicher.",
            "Mache jeden Tag zu deinem persönlichen Weihnachtswunder!",
            "Zu meiner Zeit haben wir noch mit Kohle und Schiefertafel Wunschzettel geschrieben – ihr habt es mit dieser App so einfach!",
            "Öffne dein Herz wie ein Adventskalender, Tür für Tür!",
            "Santa hat bestätigt: Du stehst auf der Liste der Netten!",
            "Lass uns eine Schneeballschlacht im Digitalen starten!"
        ];

        const randomIndex = Math.floor(Math.random() * messages.length);
        setRandomMessage(messages[randomIndex]);
    }, []);

    return (
        <div className="dashboard p-3 pt-0 text-left">
            <div className="fun-message">
                {createSnowflakes()}
                <p>{randomMessage}</p>
            </div>
            <p>Moin und willkommen bei Wunschstern!</p>
            <p>Um Teil einer Familie zu werden und Wunschlisten zu teilen, musst Du zuerst von einem Familienmitglied
                eingeladen werden. Sobald Du die Einladung angenommen hast, kannst Du Wunschlisten erstellen, Geschenke
                hinzufügen und diese mit der Familie teilen.</p>
            <div className="box-container flex flex-wrap">
                <FamiliesComponent families={families}/>
                <FamilyInvitesComponent invitations={invitations}/>
                <GiftInvitesComponent/>
            </div>
            <h2>Feedback? Ja, bitte!</h2>
            <div className="flex flex-row">
                <div className="flex-1 p-2">
                    <p><Button target={"_blank"} rel={"noreferrer"} icon={"pi pi-globe"} label={"E-Mail"}
                               href={"mailto:bastian.ehrenberg@gmail.com"}/></p>
                </div>
                <div className="flex-1 p-2">
                    <p><Button target="_blank" rel={"noreferrer"} icon={"pi pi-cloud"} label={"Trello-Board"}
                               href={"https://trello.com/invite/b/fJ8OAAB7/ATTI5a55a92e61055bd124cd411539821c23AE85717F/wunschstern"}/>
                    </p>
                </div>
            </div>
        </div>
    );
}

export default DashboardComponent;