import React, {useContext, useEffect, useState} from 'react';
import './LoginComponent.scss';
import {useNavigate} from "react-router-dom";
import AuthContext from "../../utils/authContext";
import {Message} from "primereact/message";
import {InputText} from "primereact/inputtext";
import {Button} from "primereact/button";

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

function LoginComponent() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [shouldNavigate, setShouldNavigate] = useState(false);
    const navigate = useNavigate();
    const {login} = useContext(AuthContext);
    const [message, setMessage] = useState('');

    const HandleSubmit = async (e) => {
        e.preventDefault();

        try {
            const response = await fetch(`${SERVER_URL}/users/login`, {
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify({email, password}),
            });

            if (!response.ok) {
                if (response.status === 404) {
                    setMessage('Benutzer nicht gefunden');
                } else {
                    setMessage('Fehler bei der Anmeldung');
                }
                return;
            }

            const data = await response.json();

            login(data.data.token);
            setShouldNavigate(true);

        } catch (error) {
            console.error('Fehler bei der Anmeldung:', error);
            setMessage('Fehler bei der Anmeldung');
        }
    };

    useEffect(() => {
        if (shouldNavigate) {
            navigate('/dashboard');
        }

        const rootElement = document.getElementById('root');
        if (rootElement) {
            rootElement.classList.add('login');
        }

        return () => {
            if (rootElement) {
                rootElement.classList.remove('login');
            }
        };
    }, [shouldNavigate, navigate]);

    return (
        <div className="container">
            <div className="flex flex-wrap justify-content-center pt-6 pb-6 m-2">
                <div className="bg-white-alpha-60 border-round-3xl max-w-30rem p-4 text-left">
                    <form onSubmit={HandleSubmit}>
                        <div className="flex flex-column gap-2">
                            <label htmlFor="email">E-Mail</label>
                            <InputText className="w-20rem" type={"email"} id={"email"} value={email}
                                       onChange={(e) => setEmail(e.target.value)}/>
                        </div>
                        <div className="flex flex-column gap-2 mt-1">
                            <label htmlFor="email">Passwort</label>
                            <InputText type={"password"} id={"password"} value={password}
                                       onChange={(e) => setPassword(e.target.value)}/>
                        </div>
                        <div className="flex flex-column mt-4">
                            <Button type={"submit"} className={"justify-content-center"}>Login</Button>
                        </div>
                        <div className={"flex flex-column mt-4"}>
                            <a href={"/signup"}>Registrieren</a>
                        </div>
                    </form>
                    {message !== '' && (
                        <Message severity="error" text={message} className={"mt-4 text-left w-full justify-content-start"}/>
                    )}
                </div>
            </div>
        </div>
    );
}

export default LoginComponent;