import React, {useEffect, useState} from 'react';
import {Button} from "primereact/button";
import axios from "axios";

function FamiliesComponent({invitations: initialInvitations}) {
    const [invitations, setInvitations] = useState(initialInvitations);

    useEffect(() => {
        setInvitations(initialInvitations);
    }, [initialInvitations]);

    const submitInvitation = async (id, status) => {
        try {
            const statusChange = await axios.put(`/invitations/${id}`, {status: status});

            if (statusChange.data.data.status === 'accepted' || statusChange.data.data.status === 'declined') {
                const invites = await axios.get('/invitations');
                setInvitations(invites.data.data);

            }
        } catch (error) {

        }
        window.location.reload(false);
    }

    return (
        <div className={"box w-full md:w-30rem"}>
            <div className="title">
                <i className="pi pi-comment mr-2"></i>
                Familien-Einladungen
            </div>
            <div className="content">
                <div className="flex flex-wrap">
                    {invitations && invitations.map((invitation, index) => (
                        <div className={"invitation w-full shadow-3 p-2"} key={index}>
                            {invitation.firstname} {invitation.lastname} hat sie zu {invitation.family_name} eingeladen
                            <div className="flex gap-3 m-2">
                                <Button icon="pi pi-check" severity="success" onClick={() => {
                                    submitInvitation(invitation.id, 'accepted')
                                }}/>
                                <Button icon="pi pi-trash" severity="danger" onClick={() => {
                                    submitInvitation(invitation.id, 'declined')
                                }}/>
                            </div>
                        </div>
                    ))}

                    {invitations && invitations.length === 0 && (
                        <p className={"text-center w-full"}>
                            Du hast keine Einladungen.
                        </p>
                    )}
                </div>
            </div>
        </div>
    );
}

export default FamiliesComponent;