import React, {useEffect, useState} from 'react';
import {useNavigate} from "react-router-dom";
import {InputText} from "primereact/inputtext";
import {Button} from "primereact/button";
import {useToast} from "../../ToastProvider";

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

function SignupComponent() {
    const showToast = useToast();

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [passwordRepeat, setPasswordRepeat] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');

    const [shouldNavigate, setShouldNavigate] = useState(false);
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();

        if(password !== passwordRepeat) {
            showToast({severity: 'error', summary: 'Fehler', detail: 'Die Passwörter stimmen nicht überein'});
            return;
        }

        try {
            const response = await fetch(`${SERVER_URL}/users/signup`, {
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify({email, password, firstName, lastName}),
            });

            if (!response.ok) {
                throw new Error('Anmeldung fehlgeschlagen. Bitte versuchen Sie es erneut.');
            }

            setShouldNavigate(true);
        } catch (error) {
            showToast({severity: 'error', summary: 'Fehler', detail: error.message});
        }
    };

    useEffect(() => {
        if (shouldNavigate) {
            navigate('/login');
        }

        const rootElement = document.getElementById('root');
        if (rootElement) {
            rootElement.classList.add('signup');
        }

        return () => {
            if (rootElement) {
                rootElement.classList.remove('signup');
            }
        };
    }, [shouldNavigate, navigate]);

    return (
        <div className="container">
            <div className="flex flex-wrap justify-content-center pt-8 pb-8 m-2">
                <div className="bg-white-alpha-60 border-round-3xl max-w-30rem p-5 text-left">
                    <form onSubmit={handleSubmit}>
                        <div className="flex flex-column gap-2">
                            <label htmlFor="email">E-Mail</label>
                            <InputText className="w-20rem" type={"email"} id={"email"} value={email}
                                       onChange={(e) => setEmail(e.target.value)}/>
                        </div>
                        <div className="flex flex-column gap-2">
                            <label htmlFor="password">Passwort</label>
                            <InputText className="w-20rem" type={"password"} id={"password"} value={password}
                                       onChange={(e) => setPassword(e.target.value)}/>
                        </div>
                        <div className={"flex flex-column gap-2"}>
                            <label htmlFor="passwordRepeat">Passwort wiederholen</label>
                            <InputText className="w-20rem" type={"password"} id={"passwordRepeat"} value={passwordRepeat}
                                onChange={(e) => setPasswordRepeat(e.target.value)}/>
                        </div>
                        <div className="flex flex-column gap-2">
                            <label htmlFor="firstName">Vorname</label>
                            <InputText className="w-20rem" type={"text"} id={"firstName"} value={firstName}
                                       onChange={(e) => setFirstName(e.target.value)}/>
                        </div>
                        <div className="flex flex-column gap-2">
                            <label htmlFor="lastName">Nachname</label>
                            <InputText className="w-20rem" type={"text"} id={"lastName"} value={lastName}
                                       onChange={(e) => setLastName(e.target.value)}/>
                        </div>
                        <div className="flex flex-column mt-6">
                            <Button type={"submit"} className={"justify-content-center"}>Registrieren</Button>
                        </div>
                        <div className={"flex flex-column mt-4"}>
                            <a href={"/login"}>Login</a>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default SignupComponent;