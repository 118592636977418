import React, {useState, useEffect, useCallback} from 'react';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import axios from 'axios';
import {Button} from "primereact/button";
import {confirmPopup} from "primereact/confirmpopup";
import {useToast} from "../../../ToastProvider";

function MyGiftsGrid() {
    const showToast = useToast();
    const [gifts, setGifts] = useState([]);
    const [loading, setLoading] = useState(false);

    const fetchGifts = useCallback(async () => {
        setLoading(true);
        try {
            const response = await axios.get('/users/me/gifts');
            if (response.data) {
                setGifts(response.data);
                setLoading(false);
            }
        } catch (error) {
            console.error("Fehler beim Abrufen der Geschenke:", error);
        }
    }, []);


    useEffect(() => {
        fetchGifts();
    }, [fetchGifts]);

    const acceptReleaseGift = (id) => {
        axios.post(`/gifts/${id}/unclaim`).then((response) => {
            if (response.data.success === false) {
                showToast({severity: 'error', summary: 'Fehler', detail: response.data.message});
            }

            if (response.status === 200) {
                fetchGifts();
            }
        });

        return true;
    };

    const rejectReleaseGift = (id) => {
        return true;
    };

    const confirmReleaseGift = (event, id) => {
        confirmPopup({
            target: event.currentTarget,
            message: 'Wirklich freigeben?',
            icon: 'pi pi-exclamation-triangle',
            acceptLabel: 'Ja',
            rejectLabel: 'Nein',
            accept: () => acceptReleaseGift(id),
            reject: () => rejectReleaseGift(id)
        });
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <Button disabled={true} size={"small"} label="Freigeben" className="p-button-danger p-mr-2" onClick={(event) => confirmReleaseGift(event, rowData.id)}/>
                <Button disabled={true} size={"small"} label="Als Gekauft markieren" className="p-button-success"
                        onClick={() => markAsPurchased(rowData.id)}/>
            </React.Fragment>
        );
    };

    // const releaseGift = (giftId) => {
    //     axios.put(`/gifts/${giftId}/status`, {status: 'pending'})
    //         .then(() => {
    //             setGifts(gifts.map(gift => gift.id === giftId ? {...gift, status: 'pending'} : gift));
    //         })
    //         .catch(error => {
    //             console.error('Fehler beim Freigeben des Geschenks', error);
    //         });
    // };

    const markAsPurchased = (giftId) => {
        axios.put(`/gifts/${giftId}/status`, {status: 'purchased'})
            .then(() => {
                setGifts(gifts.map(gift => gift.id === giftId ? {...gift, status: 'purchased'} : gift));
            })
            .catch(error => {
                console.error('Fehler beim Markieren als gekauft', error);
            });
    };

    const translatePriority = (priority) => {
        switch(priority) {
            case 'high':
                return 'Hoch';
            case 'medium':
                return 'Mittel';
            case 'low':
                return 'Niedrig';
            default:
                return priority;
        }
    }

    const translateStatus = (status) => {
        switch(status) {
            case 'pending':
                return 'Ausstehend';
            case 'reserved':
                return 'Reserviert';
            case 'purchased':
                return 'Gekauft';
            case 'delivered':
                return 'Geliefert';
            case 'received':
                return 'Erhalten';
            case 'not_available':
                return 'Nicht verfügbar';
            case 'cancelled':
                return 'Storniert';
            default:
                return status;
        }
    }

    return (
        <div>
            <DataTable value={gifts} paginator rows={10} loading={loading}
                       header="Diese Geschenke verschenke ich">
                <Column field="name" header="Geschenk" sortable filter/>
                <Column field="description" header="Beschreibung" sortable filter/>
                <Column field="firstname" header="Wünschender" sortable filter/>
                <Column field="link" header="Link" sortable filter/>
                <Column field="price" header="Preis" sortable filter/>
                <Column field="store" header="Geschäft" sortable filter/>
                <Column field="notes" header="Notiz" sortable filter/>
                <Column field="priority" header="Priorität" sortable filter
                        body={(rowData) => translatePriority(rowData.priority)}/>
                <Column field="status" header="Status" sortable filter
                        body={(rowData) => translateStatus(rowData.status)}/>
                <Column header="Aktionen" body={actionBodyTemplate}/>
            </DataTable>
        </div>
    );
}

export default MyGiftsGrid;