import React, { useRef, useCallback, useContext } from 'react';
import { Toast } from 'primereact/toast';

const ToastContext = React.createContext();

export const useToast = () => {
    return useContext(ToastContext);
};

function ToastProvider({ children }) {
    const toast = useRef(null);

    const showToast = useCallback((options) => {
        toast.current.show(options);
    }, []);

    return (
        <ToastContext.Provider value={showToast}>
            {children}
            <Toast ref={toast} />
        </ToastContext.Provider>
    );
}

export default ToastProvider;