import React, {useState, useEffect, useCallback} from 'react';
import axios from 'axios';
import {useNavigate, useParams} from "react-router-dom";
import {TabPanel, TabView} from "primereact/tabview";
import {Button} from "primereact/button";
import {InputText} from "primereact/inputtext";
import {Dialog} from "primereact/dialog";
import {Card} from "primereact/card";
import {Toolbar} from "primereact/toolbar";

import './FamilyDetailComponent.scss';
import MyGiftsGrid from "./MyGiftsGridComponent";
import {confirmPopup} from "primereact/confirmpopup";
import {useToast} from "../../../ToastProvider";

function FamilyDetailComponent() {
    const showToast = useToast();
    const [family, setFamily] = useState({});
    const [members, setMembers] = useState([]);
    const [user, setUser] = useState({});
    const navigate = useNavigate();
    const {id} = useParams();

    const [isAddMemberModalVisible, setIsAddMemberModalVisible] = useState(false);
    const [newMemberEmail, setNewMemberEmail] = useState('');
    const [newMemberInputError, setNewMemberInputError] = useState(false);
    const [newMemberInputErrorMessage, setNewMemberInputErrorMessage] = useState('');

    const [isFamilyMembersListModalVisible, setIsFamilyMembersListModalVisible] = useState(false);

    const [wishlists, setWishlists] = useState([]);

    const [isConfirmDialogVisible, setIsConfirmDialogVisible] = useState(false);
    const [selectedGiftId, setSelectedGiftId] = useState(null);

    const [shareMembers, setShareMembers] = useState([]);

    const getWishlists = useCallback(async () => {
        try {
            const response = await axios.get(`/families/${id}/wishlists`);
            setWishlists(response.data.data);
        } catch (error) {
            showToast({severity: 'error', summary: 'Fehler', detail: 'Fehler beim Laden der Wunschlisten'});
        }
    }, [id, showToast]);


    useEffect(() => {
        const getFamilyData = async () => {
            try {
                const response = await axios.get(`/families/${id}`);

                if (response.data.isMember) {
                    setFamily(response.data.data.family);
                    setMembers(response.data.data.members);
                } else {
                    navigate('/families');
                }
            } catch (error) {
                if (error.response.status === 403) {
                    navigate('/families');
                }
            }
        };

        getWishlists();
        getFamilyData();

        if (isConfirmDialogVisible || isAddMemberModalVisible || isFamilyMembersListModalVisible) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'unset';
        }

        const getUser = async () => {
            try {
                const response = await axios.get('/users/me');
                setUser(response.data.data);
            } catch (error) {
                showToast({severity: 'error', summary: 'Fehler', detail: 'Fehler beim Laden des Benutzers'});
            }
        }
        getUser();
    }, [id, navigate, getWishlists, isConfirmDialogVisible, isAddMemberModalVisible, isFamilyMembersListModalVisible, showToast]);

    const submitAddMember = async () => {
        try {
            await axios.post(`/families/${id}/invitations`, {email: newMemberEmail});
            setIsAddMemberModalVisible(false);
            setNewMemberEmail('');
            setNewMemberInputError(false);
            setNewMemberInputErrorMessage('');
        } catch (error) {
            setNewMemberInputError(true);
            setNewMemberInputErrorMessage(error.response.data);
            showToast({severity: 'error', summary: 'Fehler', detail: 'Fehler beim Hinzufügen des Familienmitglieds'});
        }
    }

    const addMemberDialogFooterContent = (
        <div>
            <Button
                label="Hinzufügen"
                icon="pi pi-check"
                onClick={() => {
                    submitAddMember();
                }}
            />
            <Button
                label="Abbrechen"
                icon="pi pi-times"
                className="p-button-text"
                onClick={() => setIsAddMemberModalVisible(false)}
            />
        </div>
    );

    const showShareGiftDialog = (giftId) => {
        setSelectedGiftId(giftId);
        setIsConfirmDialogVisible(true);
    };

    const handleConfirmSelectGift = () => {
        if (selectedGiftId) {
            handleSelectGift(selectedGiftId);
        }
        setIsConfirmDialogVisible(false);
        setSelectedGiftId(null);
    };

    const handleSelectGift = async (giftId) => {
        try {
            const response = await axios.post(`/gifts/${giftId}/claim`, {shareMembers});
            if (response.data.success) {
                const updatedWishlists = wishlists.map(wishlist => ({
                    ...wishlist,
                    gifts: wishlist.gifts.map(gift =>
                        gift.id === giftId ? {...gift, status: 'reserved'} : gift
                    )
                }));
                setWishlists(updatedWishlists);
            }
        } catch (error) {
            console.error("Fehler beim Auswählen des Geschenks:", error);
        }
    };

    const shareWishDialogFooter = (
        <div>
            <Button label="Bestätigen" icon="pi pi-check" onClick={handleConfirmSelectGift}/>
            <Button label="Abbrechen" icon="pi pi-times" className="p-button-text"
                    onClick={() => {
                        setIsConfirmDialogVisible(false);
                        setSelectedGiftId(null);
                        setShareMembers([]);
                    }}/>
        </div>
    );

    const acceptReleaseGift = (id) => {
        axios.post(`/gifts/${id}/unclaim`).then((response) => {
            if (response.data.success === false) {
                showToast({severity: 'error', summary: 'Fehler', detail: response.data.message});
            }

            if (response.status === 200) {
                getWishlists();
            }
        });

        return true;
    };

    const rejectReleaseGift = (id) => {
        return true;
    };

    const confirmReleaseGift = (event, id) => {
        confirmPopup({
            target: event.currentTarget,
            message: 'Wirklich freigeben?',
            icon: 'pi pi-exclamation-triangle',
            acceptLabel: 'Ja',
            rejectLabel: 'Nein',
            accept: () => acceptReleaseGift(id),
            reject: () => rejectReleaseGift(id)
        });
    };

    const wishlistHeader = (wishlistId, title, firstName, lastName) => {
        return (
            <div className="flex flex-wrap align-items-center justify-content-between p-2">
                <h3 className="p-m-0"><i className={"pi pi-book mr-1"}></i>
                    <span className={"mr-3"}>{firstName} {lastName} - {title}</span>
                </h3>
            </div>
        )
    }

    return (
        <div>
            <div className="family-header">
                <div className="flex">
                    <div className="col-1 w-3rem">
                        <Button size={"small"} className={"w-2rem h-2rem m-1"} severity="info" icon="pi pi-arrow-left"
                                aria-label="Info" rounded onClick={() => {
                            navigate('/families');
                        }}/>
                    </div>
                    <div className="col-3">
                        <h2 className="mr-5">{family.name}</h2>
                    </div>
                    <div className="col text-left">
                        <Button className={"w-3rem h-3rem m-1"} severity="info" icon="pi pi-user-plus" aria-label="Add"
                                rounded
                                onClick={() => {
                                    setIsAddMemberModalVisible(true)
                                }}/>
                        <Dialog header="Familienmitglied hinzufügen" visible={isAddMemberModalVisible}
                                onHide={() => setIsAddMemberModalVisible(false)} footer={addMemberDialogFooterContent}
                                className={"w-full md:w-30rem"}>
                            <InputText
                                className={`${newMemberInputError ? 'p-invalid' : ''} w-full`}
                                value={newMemberEmail}
                                onChange={e => setNewMemberEmail(e.target.value)}
                                placeholder="E-Mail-Adresse"
                            />
                            {newMemberInputErrorMessage && (
                                <small className="text-red-500">{newMemberInputErrorMessage}</small>
                            )}
                        </Dialog>

                        <Button className={"w-3rem h-3rem m-1"} severity="info" icon="pi pi-users" aria-label="Info"
                                rounded onClick={() => {
                            setIsFamilyMembersListModalVisible(true);
                        }}/>

                        <Dialog header={"Familienmitglieder"} visible={isFamilyMembersListModalVisible}
                                onHide={() => setIsFamilyMembersListModalVisible(false)}
                                className={"w-full md:w-30rem"}>
                            <ul className={"list-none m-0 p-0"}>
                                {members && members.map(member => (
                                    <li key={member.id}
                                        className={"border-1 border-black-alpha-40 mb-2 border-round-lg p-2"}>
                                        {member.firstname} {member.lastname}
                                    </li>
                                ))}
                            </ul>
                        </Dialog>
                    </div>
                </div>
            </div>
            <TabView>
                <TabPanel header="Wunschlisten">
                    {wishlists && wishlists.map(wishlist => (
                        <Card key={wishlist.id}
                              header={wishlistHeader(wishlist.id, wishlist.title, wishlist.firstname, wishlist.lastname)}
                              className="w-full wishlist-card mb-6 shadow-3">
                            <div
                                className="flex flex-row flex-wrap gap-5 m-0 mt-2 justify-content-center md:justify-content-start">
                                {wishlist.gifts && wishlist.gifts.map(gift => (
                                    <Card key={gift.id} className="w-27rem max-w-27rem shadow-4 wishlist-card">
                                        <div
                                            className={"card-header p-0 w-full border-round-lg h-12rem flex flex-column justify-content-between"}>
                                            <Toolbar className={"bg-black-alpha-20 m-2 p-0"}/>
                                            <span className={"bg-white-alpha-70 p-2 font-bold text-base text-gray-800"}>{gift.name}</span>
                                        </div>
                                        <div className="flex flex-wrap flex-column align-items-start p-2">
                                            <div className={"flex flex-row flex-wrap gap-2 mb-2"}>
                                                {gift.priority === 'low' && (
                                                    <Button size={"small"} severity="info"
                                                            icon={"pi pi-caret-down"} rounded text raised
                                                            tooltip={"Priorität Niedrig"}
                                                            tooltipOptions={{position: 'top'}}/>
                                                )}
                                                {gift.priority === 'medium' && (
                                                    <Button size={"small"} severity="info"
                                                            icon={"pi pi-minus"}
                                                            rounded text raised
                                                            tooltip={"Priorität Mittel"}
                                                            tooltipOptions={{position: 'top'}}/>
                                                )}
                                                {gift.priority === 'high' && (
                                                    <Button size={"small"} severity="info"
                                                            icon={"pi pi-caret-up"} rounded text raised
                                                            tooltip={"Priorität Hoch"}
                                                            tooltipOptions={{position: 'top'}}/>
                                                )}

                                                {gift.notes && (
                                                    <div className={"notes"}>
                                                        <Button size={"small"} icon={"pi pi-info-circle"}
                                                                rounded text raised
                                                                tooltip={gift.notes}
                                                                tooltipOptions={{position: 'top'}}/>
                                                    </div>
                                                )}

                                                {gift.link && (
                                                    <div className={"link"}>
                                                        <Button size={"small"} icon={"pi pi-link"}
                                                                rounded text raised
                                                                tooltip={gift.link}
                                                                tooltipOptions={{position: 'top'}}
                                                                onClick={() => {
                                                                    if (gift.link.startsWith('http://') || gift.link.startsWith('https://')) {
                                                                        window.open(gift.link, '_blank');
                                                                    } else {
                                                                        window.open('https://' + gift.link, '_blank');
                                                                    }
                                                                }}/>
                                                    </div>
                                                )}

                                                {gift.status === 'pending' && (
                                                    <Button size={"small"} icon={"pi pi-question-circle"}
                                                            rounded text raised
                                                            tooltip={"Status: Offen"}
                                                            tooltipOptions={{position: 'top'}}/>
                                                )}
                                                {gift.status === 'wished' && (
                                                    <Button size={"small"} icon={"pi pi-gift"} rounded text
                                                            raised tooltip={"Status: Gewünscht"}
                                                            tooltipOptions={{position: 'top'}}/>
                                                )}
                                                {gift.status === 'purchased' && (
                                                    <Button size={"small"} icon={"pi pi-shopping-cart"}
                                                            rounded
                                                            text
                                                            raised tooltip={"Status: Gekauft"}
                                                            tooltipOptions={{position: 'top'}}/>
                                                )}
                                                {gift.status === 'delivered' && (
                                                    <Button size={"small"} icon={"pi pi-truck"} rounded
                                                            text
                                                            raised tooltip={"Status: Versendet"}
                                                            tooltipOptions={{position: 'top'}}/>
                                                )}
                                                {gift.status === 'received' && (
                                                    <Button size={"small"} icon={"pi pi-check-circle"}
                                                            rounded text raised
                                                            tooltip={"Status: Erhalten"}
                                                            tooltipOptions={{position: 'top'}}/>
                                                )}
                                                {gift.status === 'not_available' && (
                                                    <Button size={"small"} icon={"pi pi-times-circle"}
                                                            rounded text raised
                                                            tooltip={"Status: Nicht verfügbar"}
                                                            tooltipOptions={{position: 'top'}}/>
                                                )}
                                                {gift.status === 'cancelled' && (
                                                    <Button size={"small"} icon={"pi pi-ban"}
                                                            rounded text raised
                                                            tooltip={"Status: Storniert"}
                                                            tooltipOptions={{position: 'top'}}/>
                                                )}

                                                {gift.price > 0 && (
                                                    <div className={"price"}>
                                                        <Button size={"small"} icon={"pi pi-euro"}
                                                                rounded text raised
                                                                tooltip={`${gift.price} €`}
                                                                tooltipOptions={{position: 'top'}}/>
                                                    </div>
                                                )}

                                                {gift.store && (
                                                    <div className={"store"}>
                                                        <Button size={"small"} icon={"pi pi-shopping-cart"}
                                                                rounded text
                                                                raised tooltip={gift.store}
                                                                tooltipOptions={{position: 'top'}}/>
                                                    </div>
                                                )}
                                            </div>
                                            {gift.status === 'pending' && (
                                                <div className={"flex flex-row flex-wrap gap-2"}>
                                                    <Button size="small" className={"w-full"}
                                                            label={'Schenken'}
                                                            icon="pi pi-check"
                                                            onClick={() => showShareGiftDialog(gift.id)}
                                                            disabled={gift.status === 'selected'}/>
                                                </div>
                                            )}
                                            {gift.status === 'reserved' && (
                                                <div className={"flex flex-row flex-wrap gap-2"}>
                                                    <Button size="small" className={"w-full"} label={"Freigeben"}
                                                            icon="pi pi-backward"
                                                            onClick={(event) => confirmReleaseGift(event, gift.id)}/>
                                                </div>
                                            )}
                                            <small
                                                className="w-full text-left mb-4 mt-2 p-2 border-top-1 border-300 text-sm">{gift.description}</small>
                                        </div>
                                        <Dialog header="Geschenk bestätigen" visible={isConfirmDialogVisible}
                                                modal footer={shareWishDialogFooter}
                                                onHide={() => setIsConfirmDialogVisible(false)}>
                                            Sind Sie sicher, dass Sie dieses Geschenk auswählen möchten?

                                            <p className="text-center">Möchtest du das Geschenk mit jemandem Teilen?</p>
                                            <div className="flex flex-wrap">
                                                {members && members.filter(member => member.id !== wishlist.user_id && member.id !== user.id).map((member, index) => (
                                                    <div className={"invitation w-full shadow-3 p-2"} key={index}>
                                                        <div className="flex gap-3 m-1">
                                                            <div className="flex flex-grow-1">
                                                                <p>{member.firstname} {member.lastname}</p>
                                                            </div>

                                                            <div className="flex-none flex">
                                                                <Button icon="pi pi-check" severity="success"
                                                                        title={"Teilen mit " + member.firstname}
                                                                        disabled={shareMembers.includes(member.id)}
                                                                        onClick={() => {
                                                                            setShareMembers([...shareMembers, member.id])
                                                                        }}/>
                                                                <Button icon="pi pi-trash" severity="danger"
                                                                        title={"Nicht mehr teilen mit " + member.firstname}
                                                                        disabled={!shareMembers.includes(member.id)}
                                                                        onClick={() => {
                                                                            setShareMembers(shareMembers.filter(shareMember => shareMember !== member.id))
                                                                        }}/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        </Dialog>
                                    </Card>
                                ))}

                            </div>
                        </Card>
                    ))}

                </TabPanel>

                <TabPanel header="Schenke ich">
                    <MyGiftsGrid/>
                </TabPanel>
            </TabView>

        </div>
    );
}

export default FamilyDetailComponent;