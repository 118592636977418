import React, {useContext, useEffect} from 'react';
import './HomeComponent.scss';
import {useNavigate} from "react-router-dom";
import AuthContext from "../../utils/authContext";
import {Button} from "primereact/button";

function HomeComponent() {
    const navigate = useNavigate();
    const {isAuth} = useContext(AuthContext);

    useEffect(() => {
        if (isAuth) {
            navigate('/dashboard');
        }

        const rootElement = document.getElementById('root');
        if (rootElement) {
            rootElement.classList.add('home');
        }

        return () => {
            if (rootElement) {
                rootElement.classList.remove('home');
            }
        };
    }, [isAuth, navigate]);

    return (
        <div className="container">
            <div className="flex flex-wrap align-items-center justify-content-center pt-6 pb-6 m-2">
                <div className="bg-white-alpha-60 border-round max-w-30rem p-2 pt-4 pb-4">
                    <h1>Ho! Ho! Ho!</h1>
                    <p>
                        Entdecken Sie die einfachste Möglichkeit, Ihre Weihnachtswünsche mit Ihrer Familie zu teilen.
                        Melden Sie sich an, um Ihre Wunschzettel zu erstellen, zu verwalten und die perfekten Geschenke
                        für Ihre Lieben zu finden.
                    </p>
                    <Button className={"m-2"} onClick={() => navigate('/login')}>Login</Button>
                    <Button className={"m-2"} onClick={() => navigate('/signup')}>Registrieren</Button>
                </div>
            </div>
        </div>
    );
}

export default HomeComponent;