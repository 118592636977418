import React, {useState, useEffect} from 'react';
import {Button} from 'primereact/button';
import {Card} from 'primereact/card';
import {Toolbar} from 'primereact/toolbar';
import axios from 'axios';
import './WishListOverviewComponent.scss';
import {Dialog} from "primereact/dialog";
import {InputText} from "primereact/inputtext";
import {InputTextarea} from "primereact/inputtextarea";
import {Dropdown} from "primereact/dropdown";
import {confirmPopup} from "primereact/confirmpopup";

function WishListsOverviewComponent() {
    const [wishlists, setWishlists] = useState([]);
    const [showAddModal, setShowAddModal] = useState(false);
    const [newWishlistName, setNewWishlistName] = useState('');

    const [activeWishlist, setActiveWishlist] = useState(null);
    const [activeGiftId, setActiveGiftId] = useState(null);

    // gift dialog modal
    const [giftDialogType, setGiftDialogType] = useState('add');

    const [showFormGiftModal, setShowFormGiftModal] = useState(false);
    const [formGiftName, setFormGiftName] = useState('');
    const [formGiftDescription, setFormGiftDescription] = useState('');
    const [formGiftLink, setFormGiftLink] = useState('');
    const [formGiftPrice, setFormGiftPrice] = useState('');
    const [formGiftPriority, setFormGiftPriority] = useState('low'); // Standardpriorität
    const [formGiftStore, setFormGiftStore] = useState('');
    const [formGiftNotes, setFormGiftNotes] = useState('');

    // families
    const [myFamilies, setMyFamilies] = useState([]);
    const [activeFamily, setActiveFamily] = useState(null);

    const [editWishlistId, setEditWishlistId] = useState(null);
    const [editedWishlistName, setEditedWishlistName] = useState('');
    const [editedWishlistStatus, setEditedWishlistStatus] = useState('pending');

    const fetchWishlists = async () => {
        try {
            const response = await axios.get('/wishlists');
            if (response.data) {
                setWishlists(response.data.data);
            }
        } catch (error) {
            console.error("Fehler beim Abrufen der Wunschlisten:", error);
        }
    };

    const fetchFamilies = async () => {
        try {
            const response = await axios.get('/users/me/families');
            if (response.data) {
                setMyFamilies(response.data);
                setActiveFamily(response.data.length > 0 ? response.data[0].id : null);
            }
        } catch (error) {
            console.error("Fehler beim Abrufen der Familien:", error);
        }
    };

    useEffect(() => {
        fetchFamilies();
        fetchWishlists();
    }, []);

    const initGiftToWishlistForm = () => {
        setShowFormGiftModal(false);
        setFormGiftName('');
        setFormGiftDescription('');
        setFormGiftLink('');
        setFormGiftPrice('');
        setFormGiftPriority('low');
        setFormGiftStore('');
        setFormGiftNotes('');
    };

    const handleAddWishlist = async () => {
        try {
            const response = await axios.post('/wishlists', {title: newWishlistName});
            if (response.data) {
                setWishlists([...wishlists, {
                    id: response.data.data.id,
                    title: response.data.data.title,
                    familyName: response.data.data.familyName,
                    status: response.data.data.status
                }]);
                setNewWishlistName('');
                setActiveFamily(null);
                setShowAddModal(false);
            }
        } catch (error) {
            console.error("Fehler beim Hinzufügen einer Wunschliste:", error);
        }
    }

    const handleEditWishlist = async () => {
        try {
            const response = await axios.put(`/wishlists/${editWishlistId}`, {
                title: editedWishlistName,
                status: editedWishlistStatus
            });
            if (response.data) {
                const updatedWishlists = wishlists.map(wishlist =>
                    wishlist.id === editWishlistId
                        ? {...wishlist, title: editedWishlistName, status: editedWishlistStatus}
                        : wishlist
                );
                setWishlists(updatedWishlists);
                setEditWishlistId(null);
                setEditedWishlistName('');
            }
        } catch (error) {
            console.error("Fehler beim Bearbeiten einer Wunschliste:", error);
        }
    };

    const handleAddGiftToWishlist = async () => {
        try {
            await axios.post('/gifts', {
                wishlist_id: activeWishlist,
                name: formGiftName,
                description: formGiftDescription,
                link: formGiftLink,
                price: formGiftPrice,
                priority: formGiftPriority,
                store: formGiftStore,
                notes: formGiftNotes
            });

            await fetchWishlists();
        } catch (error) {
            console.error("Fehler beim Hinzufügen einer Wunschliste:", error);
        }

        initGiftToWishlistForm();
    }

    const handleEditGiftToWishlist = async () => {
        try {
            await axios.put(`/gifts/${activeGiftId}`, {
                name: formGiftName,
                description: formGiftDescription,
                link: formGiftLink,
                price: formGiftPrice,
                priority: formGiftPriority,
                store: formGiftStore,
                notes: formGiftNotes
            });

            await fetchWishlists();
        } catch (error) {
            console.error("Fehler beim Hinzufügen einer Wunschliste:", error);
        }

        initGiftToWishlistForm();
    }

    const acceptDeleteWishlist = (id) => {
        axios.delete(`/wishlists/${id}`).then(() => {
            fetchWishlists();
        });

        return true;
    };

    const rejectDeleteWishlist = (id) => {
        return true;
    };

    const confirmDeleteWishlist = (event, id) => {
        confirmPopup({
            target: event.currentTarget,
            message: 'Wirklich löschen?',
            icon: 'pi pi-exclamation-triangle',
            acceptLabel: 'Ja',
            rejectLabel: 'Nein',
            accept: () => acceptDeleteWishlist(id),
            reject: () => rejectDeleteWishlist(id)
        });
    };


    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Button icon="pi pi-plus" className="p-button-success p-mr-2" rounded
                        label={"Wunschzettel"}
                        onClick={() => setShowAddModal(true)}/>
            </React.Fragment>
        )
    };

    const wishlistHeader = (wishlistId, title, familyName, status) => {
        return (
            <div className="flex flex-wrap align-items-center justify-content-between p-2 gap-3">
                <h3 className="p-m-0"><i className={"pi pi-book mr-1"}></i>
                    <span className={"mr-3"}>{familyName} - {title}</span>
                    {status === 'approved' && (
                        <i title={"Freigegeben"} className={"pi pi-check-circle mr-1"}></i>
                    )}
                    {status === 'archived' && (
                        <i title={"Archiviert"} className={"pi pi-ban mr-1"}></i>
                    )}
                </h3>
                <Toolbar right={rightGiftToolbarTemplate(wishlistId)}/>
            </div>
        )
    }

    const giftDialogFooterContent = () => (
        <div>
            {giftDialogType === 'add' ? (
                <Button onClick={handleAddGiftToWishlist}>Hinzufügen</Button>
            ) : (
                <Button onClick={handleEditGiftToWishlist}>Speichern</Button>
            )}
            <Button onClick={initGiftToWishlistForm}>Abbrechen</Button>
        </div>
    );

    const rightGiftToolbarTemplate = (wishlistId) => {
        return (
            <React.Fragment>
                <Button icon="pi pi-plus" className="p-button-success h-2rem mr-2" label={"Geschenk"} rounded
                        onClick={() => {
                            setGiftDialogType('add');
                            setActiveWishlist(wishlistId);
                            setShowFormGiftModal(true);
                        }}/>
                <Button className="h-2rem w-2rem mr-2" icon="pi pi-pencil" rounded severity="warning"
                        aria-label="Bearbeiten" onClick={() => {
                    setEditedWishlistName(wishlists.find(wishlist => wishlist.id === wishlistId).title);
                    setEditedWishlistStatus(wishlists.find(wishlist => wishlist.id === wishlistId).status);
                    setEditWishlistId(wishlistId);
                }}/>
                <Button className="h-2rem w-2rem" icon="pi pi-times" rounded severity="danger" aria-label="Löschen"
                        onClick={(event) => {
                            confirmDeleteWishlist(event, wishlistId);
                        }}/>
            </React.Fragment>
        )
    }

    /*
     * Wish Toolbar
     */
    const editGift = (id) => {
        const gift = wishlists.flatMap(list => list.gifts).find(gift => gift.id === id);

        setShowFormGiftModal(true);
        setFormGiftName(gift.name);
        setFormGiftDescription(gift.description);
        setFormGiftLink(gift.link);
        setFormGiftPrice(gift.price.toString().replace('.', ','));
        setFormGiftPriority(gift.priority);
        setFormGiftStore(gift.store);
        setFormGiftNotes(gift.notes);
    };

    const acceptDeleteGift = (id) => {
        axios.delete(`/gifts/${id}`).then(() => {
            fetchWishlists();
        });

        return true;
    };

    const rejectDeleteGift = (id) => {
        return true;
    };

    const confirmDeleteGift = (event, id) => {
        confirmPopup({
            target: event.currentTarget,
            message: 'Wirklich löschen?',
            icon: 'pi pi-exclamation-triangle',
            acceptLabel: 'Ja',
            rejectLabel: 'Nein',
            accept: () => acceptDeleteGift(id),
            reject: () => rejectDeleteGift(id)
        });
    };

    const wishToolbar = (id) => {
        return (
            <React.Fragment>
                <div className="flex gap-2 p-2">
                    <Button size={"small"} title={"Bearbeiten"} icon="pi pi-pencil" severity={"warning"} rounded
                            onClick={() => {
                                setGiftDialogType('edit');
                                setActiveGiftId(id)
                                editGift(id)
                            }}/>
                    <Button size={"small"} title={"Löschen"} icon="pi pi-trash" severity={"danger"} rounded
                            onClick={(event) => {
                                confirmDeleteGift(event, id);
                            }}/>
                </div>
            </React.Fragment>
        )
    }

    const addDialogFooterContent = () => (
        <div>
            <Button className={"btn"} onClick={handleAddWishlist}
                    disabled={activeFamily === null || newWishlistName === '' || editedWishlistStatus === ''}>Hinzufügen</Button>
            <Button className={"btn"} onClick={() => setShowAddModal(false)}>Abbrechen</Button>
        </div>
    );

    const editWishlistDialogFooterContent = (
        <div>
            <Button onClick={handleEditWishlist}>Speichern</Button>
            <Button onClick={() => setEditWishlistId(null)}>Abbrechen</Button>
        </div>
    );

    return (
        <div className="wishlists-container p-2">
            <p>Hier kannst Du deine Wünsche hinterlegen. Bitte beachte. Damit andere deine Wünsche sehen können, musst du die Wunschliste bearbeiten und freigeben</p>
            <Toolbar left={leftToolbarTemplate}/>
            <Dialog header="Wunschliste hinzufügen" visible={showAddModal}
                    onHide={() => setShowAddModal(false)} footer={addDialogFooterContent}
                    className={"w-full md:w-30rem"}>
                <div className="flex flex-column">
                    <div className="flex">
                        <InputText className={"w-full mb-2"}
                                   value={newWishlistName}
                                   onChange={e => setNewWishlistName(e.target.value)}
                                   onKeyDown={e => {
                                       if (e.key === 'Enter' && activeFamily !== null && newWishlistName !== '' && editedWishlistStatus !== '') {
                                           handleAddWishlist();
                                       }
                                   }}
                                   placeholder={"z.B. Wunschzettel " + new Date().getFullYear()}
                        />
                    </div>
                    <div className="flex">
                        <Dropdown className={"w-full mb-2"}
                                  placeholder={"Familie auswählen"}
                                  optionLabel={"name"}
                                  optionValue={"id"}
                                  options={myFamilies}
                                  value={activeFamily}
                                  onChange={(e) => setActiveFamily(e.value)}/>
                    </div>
                    <div className="flex">
                        <Dropdown className={"w-full"}
                                  placeholder={"Status auswählen"}
                                  optionLabel={"name"}
                                  optionValue={"value"}
                                  options={[
                                      {name: 'Offen', value: 'pending'},
                                      {name: 'Freigegeben', value: 'approved'},
                                      {name: 'Archiviert', value: 'archived'}
                                  ]}
                                  value={editedWishlistStatus}
                                  onChange={(e) => setEditedWishlistStatus(e.value)}/>
                    </div>
                </div>
            </Dialog>

            <Dialog header="Wunschliste bearbeiten" visible={editWishlistId != null}
                    onHide={() => setEditWishlistId(null)} footer={editWishlistDialogFooterContent}
                    className={"w-full md:w-30rem"}>
                <div className="flex flex-column">
                    <div className="flex align-items-center justify-content-center">
                        <InputText className={"w-full"}
                                   value={editedWishlistName}
                                   onChange={(e) => setEditedWishlistName(e.target.value)}
                                   placeholder="Wunschlistentitel"
                        />
                    </div>
                    <div className="flex align-items-center justify-content-center">
                        <Dropdown className={"w-full"}
                                  placeholder={"Status auswählen"}
                                  optionLabel={"name"}
                                  optionValue={"value"}
                                  options={[
                                      {name: 'Offen', value: 'pending'},
                                      {name: 'Freigegeben', value: 'approved'},
                                      {name: 'Archiviert', value: 'archived'}
                                  ]}
                                  value={editedWishlistStatus}
                                  onChange={(e) => setEditedWishlistStatus(e.value)}/>
                    </div>
                </div>
            </Dialog>

            <Dialog
                header={"Geschenk"}
                visible={showFormGiftModal}
                className={"w-full lg:m-3"}
                onHide={() => {
                    setShowFormGiftModal(false);
                    initGiftToWishlistForm()
                }}
                footer={giftDialogFooterContent}
                maximizable={true}
            >
                <div className="p-fluid">
                    <div className="field grid pt-2">
                        <label htmlFor="name" className="col-12 mb-2 md:col-2 md:mb-0">Name</label>
                        <div className="col-12 md:col-10">
                            <InputText
                                id="name"
                                value={formGiftName}
                                onChange={e => setFormGiftName(e.target.value)}
                                placeholder={"z.B. Nintendo Switch"}
                            />
                        </div>
                    </div>

                    <div className="field grid">
                        <label htmlFor="description" className="col-12 mb-2 md:col-2 md:mb-0">Beschreibung</label>
                        <div className="col-12 md:col-10">
                            <InputTextarea
                                id="description"
                                value={formGiftDescription}
                                onChange={e => setFormGiftDescription(e.target.value)}
                                placeholder={"z.B. Nintendo Switch mit 2 Controllern und Mario Kart 8 Deluxe"}
                            />
                        </div>
                    </div>

                    <div className="field grid">
                        <label htmlFor="link" className="col-12 mb-2 md:col-2 md:mb-0">Link</label>
                        <div className="col-12 md:col-10">
                            <InputText
                                id="link"
                                value={formGiftLink}
                                placeholder={"https://kaufmich.de"}
                                onChange={e => setFormGiftLink(e.target.value)}
                            />
                        </div>
                    </div>

                    <div className="field grid">
                        <label htmlFor="price" className="col-12 mb-2 md:col-2 md:mb-0">Preis</label>
                        <div className="col-12 md:col-10">
                            <InputText
                                id="price"
                                value={formGiftPrice}
                                placeholder={"0,00 €"}
                                onChange={e => setFormGiftPrice(e.target.value)}
                            />
                        </div>
                    </div>

                    <div className="field grid">
                        <label htmlFor="priority" className="col-12 mb-2 md:col-2 md:mb-0">Priorität</label>
                        <div className="col-12 md:col-10">
                            <Dropdown
                                id="priority"
                                optionLabel="name"
                                optionValue="value"
                                value={formGiftPriority}
                                options={[
                                    {name: 'Niedrig', value: 'low'},
                                    {name: 'Mittel', value: 'medium'},
                                    {name: 'Hoch', value: 'high'}
                                ]}
                                onChange={e => setFormGiftPriority(e.value)}
                            />
                        </div>
                    </div>

                    <div className="field grid">
                        <label htmlFor="store" className="col-12 mb-2 md:col-2 md:mb-0">Geschäft</label>
                        <div className="col-12 md:col-10">
                            <InputText
                                id="store"
                                placeholder={"z.B. Amazon"}
                                value={formGiftStore}
                                onChange={e => setFormGiftStore(e.target.value)}
                            />
                        </div>
                    </div>

                    <div className="field grid">
                        <label htmlFor="notes" className="col-12 mb-2 md:col-2 md:mb-0">Notizen</label>
                        <div className="col-12 md:col-10">
                            <InputTextarea
                                id="notes"
                                value={formGiftNotes}
                                onChange={e => setFormGiftNotes(e.target.value)}
                                placeholder={"z.B. Farbe: Rot"}
                            />
                        </div>
                    </div>
                </div>
            </Dialog>

            <div className="flex flex-wrap">
                {wishlists && wishlists.map(list => (
                    <Card key={list.id} header={wishlistHeader(list.id, list.title, list.familyName, list.status)}
                          className="w-full wishlist-card mb-6 shadow-3">
                        <div className="flex flex-wrap gap-5 justify-content-center lg:justify-content-start p-3">
                            {list.gifts && list.gifts.map(gift => (
                                <Card key={gift.id} className="w-27rem max-w-27rem shadow-4">
                                    <div
                                        className={"card-header p-0 w-full border-round-lg h-12rem flex flex-column justify-content-between"}>
                                        <Toolbar className={"bg-black-alpha-10 m-0 p-0"} right={wishToolbar(gift.id)}/>
                                        <span
                                            className={"bg-white-alpha-70 p-2 font-bold text-base text-gray-800"}>{gift.name}</span>
                                    </div>
                                    <div className="flex flex-wrap flex-column align-items-start p-2">
                                        <div className={"flex flex-row flex-wrap gap-2"}>
                                            {gift.priority === 'low' && (
                                                <Button size={"small"} severity="info"
                                                        icon={"pi pi-caret-down"} rounded text raised
                                                        tooltip={"Priorität Niedrig"}
                                                        tooltipOptions={{position: 'top'}}/>
                                            )}
                                            {gift.priority === 'medium' && (
                                                <Button size={"small"} severity="info" icon={"pi pi-minus"}
                                                        rounded text raised
                                                        tooltip={"Priorität Mittel"}
                                                        tooltipOptions={{position: 'top'}}/>
                                            )}
                                            {gift.priority === 'high' && (
                                                <Button size={"small"} severity="info"
                                                        icon={"pi pi-caret-up"} rounded text raised
                                                        tooltip={"Priorität Hoch"}
                                                        tooltipOptions={{position: 'top'}}/>
                                            )}

                                            {gift.notes && (
                                                <div className={"notes"}>
                                                    <Button size={"small"} icon={"pi pi-info-circle"}
                                                            rounded text raised
                                                            tooltip={gift.notes}
                                                            tooltipOptions={{position: 'top'}}/>
                                                </div>
                                            )}

                                            {gift.link && (
                                                <div className={"link"}>
                                                    <Button size={"small"} icon={"pi pi-link"}
                                                            rounded text raised
                                                            tooltip={gift.link}
                                                            tooltipOptions={{position: 'top'}}
                                                            onClick={() => {
                                                                window.open(gift.link, '_blank');
                                                            }}/>
                                                </div>
                                            )}

                                            {gift.status === 'pending' && (
                                                <Button size={"small"} icon={"pi pi-question-circle"}
                                                        rounded text raised
                                                        tooltip={"Status: Offen"}
                                                        tooltipOptions={{position: 'top'}}/>
                                            )}
                                            {gift.status === 'wished' && (
                                                <Button size={"small"} icon={"pi pi-gift"}
                                                        rounded text raised
                                                        tooltip={"Status: Gewünscht"}
                                                        tooltipOptions={{position: 'top'}}/>
                                            )}
                                            {gift.status === 'purchased' && (
                                                <Button size={"small"} icon={"pi pi-shopping-cart"}
                                                        rounded text raised
                                                        tooltip={"Status: Gekauft"}
                                                        tooltipOptions={{position: 'top'}}/>
                                            )}
                                            {gift.status === 'delivered' && (
                                                <Button size={"small"} icon={"pi pi-truck"}
                                                        rounded text raised
                                                        tooltip={"Status: Versendet"}
                                                        tooltipOptions={{position: 'top'}}/>
                                            )}
                                            {gift.status === 'received' && (
                                                <Button size={"small"} icon={"pi pi-check-circle"}
                                                        rounded text raised
                                                        tooltip={"Status: Erhalten"}
                                                        tooltipOptions={{position: 'top'}}/>
                                            )}
                                            {gift.status === 'not_available' && (
                                                <Button size={"small"} icon={"pi pi-times-circle"}
                                                        rounded text raised
                                                        tooltip={"Status: Nicht verfügbar"}
                                                        tooltipOptions={{position: 'top'}}/>
                                            )}
                                            {gift.status === 'cancelled' && (
                                                <Button size={"small"} icon={"pi pi-ban"}
                                                        rounded text raised
                                                        tooltip={"Status: Storniert"}
                                                        tooltipOptions={{position: 'top'}}/>
                                            )}

                                            {gift.price > 0 && (
                                                <div className={"price"}>
                                                    <Button size={"small"} icon={"pi pi-euro"}
                                                            rounded text raised
                                                            tooltip={`${gift.price} €`}
                                                            tooltipOptions={{position: 'top'}}/>
                                                </div>
                                            )}

                                            {gift.store && (
                                                <div className={"store"}>
                                                    <Button size={"small"} icon={"pi pi-shopping-cart"}
                                                            rounded text raised
                                                            tooltip={gift.store}
                                                            tooltipOptions={{position: 'top'}}/>
                                                </div>
                                            )}
                                        </div>
                                        <small
                                            className="w-full text-left mb-4 mt-2 p-2 border-top-1 border-400 text-sm">{gift.description}</small>
                                    </div>
                                </Card>
                            ))}

                            {list.gifts.length === 0 && (
                                <div className="flex flex-column align-items-center justify-content-center w-full">
                                    <h3 className="p-m-0">Keine Geschenke vorhanden</h3>
                                    <p className="p-m-0">Füge ein neues Geschenk hinzu</p>
                                </div>
                            )}
                        </div>
                    </Card>
                ))}

                {wishlists.length === 0 && (
                    <div className="flex flex-column align-items-center justify-content-center w-full">
                        <h3 className="p-m-0">Keine Wunschlisten vorhanden</h3>
                        <p className="p-m-0">Füge eine neue Wunschliste hinzu</p>
                    </div>
                )}
            </div>
        </div>
    )
}

export default WishListsOverviewComponent;