import React, {useEffect, useState} from 'react';
import {Button} from "primereact/button";
import axios from "axios";

function GiftInvitesComponent() {
    const [giftInvites, setGiftInvites] = useState([]);

    useEffect(() => {
        const fetchGiftInvites = async () => {
            try {
                const response = await axios.get('/users/me/gift-invitations');
                setGiftInvites(response.data);
            } catch (error) {
                console.error("Fehler beim Abrufen der Geschenkeinladungen:", error);
            }
        };

        fetchGiftInvites();
    }, []);

    const handleGiftInviteResponse = async (id, response) => {
        try {
            await axios.put(`/users/me/gift-invitations/${id}`, {status: response});
            const updatedInvites = await axios.get('/users/me/gift-invitations');
            setGiftInvites(updatedInvites.data);
        } catch (error) {
            console.error("Fehler bei der Verarbeitung der Geschenkeinladung:", error);
        }
    };

    return (
        <div className={"box flex-1 md:w-34rem"}>
            <div className="title">
                <i className="pi pi-gift mr-2"></i>
                Einladungen geteiltes Geschenk
            </div>
            <div className="content">
                <div className="flex flex-wrap">
                    {giftInvites && giftInvites.map((invite, index) => (
                        <div className={"flex flex-row invitation w-full shadow-3 p-2"} key={index}>
                            <div className="flex-1">
                                {invite.fromUserFirstName} {invite.fromUserLastName} möchte mit dir
                                zusammen {invite.toUserFirstName} {invite.toUserLastName} das Geschenk "{invite.giftTitle}" schenken
                            </div>
                            <div className="flex">
                                <Button className={"p-button-success mr-2"} label="Annehmen"
                                        onClick={() => handleGiftInviteResponse(invite.id, 'confirmed')}
                                icon={"pi pi-check"} size={"small"}/>
                                <Button className={"p-button-danger"} label="Ablehnen"
                                        onClick={() => handleGiftInviteResponse(invite.id, 'rejected')}
                                icon={"pi pi-times"} size={"small"}/>
                            </div>
                        </div>
                    ))}
                    {giftInvites && giftInvites.length === 0 && (
                        <p className={"text-center w-full"}>
                            Du hast keine Einladungen.
                        </p>
                    )}
                </div>
            </div>
        </div>
    );
}

export default GiftInvitesComponent;
