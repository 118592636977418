import React, {useRef} from 'react';
import './App.scss';

import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import DashboardComponent from "./components/Dashboard/DashboardComponent";
import LoginComponent from "./components/Login/LoginComponent";
import SignupComponent from "./components/Signup/SignupComponent";
import HomeComponent from "./components/Home/HomeComponent";
import FooterComponent from "./components/Layout/FooterComponent";
import HeaderComponent from "./components/Layout/HeaderComponent";
import FamilyOverviewComponent from "./components/FamilyOverview/FamilyOverviewComponent";
import {AuthProvider} from "./utils/authContext";
import PrivateRoute from "./utils/PrivateRoute";
import AxiosInterceptorComponent from "./utils/AxiosInterceptorComponent";

import {PrimeReactProvider} from "primereact/api";
import "primereact/resources/themes/md-light-indigo/theme.css";
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import FamilyDetailComponent from "./components/FamilyOverview/FamilyDetail/FamilyDetailComponent";
import WishListOverviewComponent from "./components/WishList/WishListOverviewComponent";
import {Toast} from "primereact/toast";
import {ConfirmPopup} from "primereact/confirmpopup";

import ToastProvider from './ToastProvider';

function App() {
    const toast = useRef(null);

    return (
        <div className="App">
            <AuthProvider>
                <ToastProvider>
                    <PrimeReactProvider>
                        <AxiosInterceptorComponent/>
                        <HeaderComponent/>
                        <main className="App-content">
                            <Router>
                                <Routes>
                                    <Route path="/login" element={<LoginComponent/>}/>
                                    <Route path="/signup" element={<SignupComponent/>}/>
                                    <Route path="/home" element={<HomeComponent/>}/>
                                    <Route path="/" element={<PrivateRoute/>}>
                                        <Route path="/dashboard" element={<DashboardComponent/>}/>
                                        <Route path="/families" element={<FamilyOverviewComponent/>}/>
                                        <Route path="/families/:id" element={<FamilyDetailComponent/>}/>
                                        <Route path="/wishlist" element={<WishListOverviewComponent/>}/>
                                        <Route path="/" element={<HomeComponent/>}/>
                                    </Route>
                                    <Route path="*" element={<h1>404 - Seite nicht gefunden</h1>}/>
                                </Routes>
                            </Router>
                        </main>
                        <Toast ref={toast}/>
                        <ConfirmPopup/>
                        <FooterComponent/>
                    </PrimeReactProvider>
                </ToastProvider>
            </AuthProvider>
            <div className="blobber">
                <div className="shape-blob"></div>
                <div className="shape-blob one"></div>
                <div className="shape-blob two"></div>
                <div className="shape-blob three"></div>
                <div className="shape-blob four"></div>
                <div className="shape-blob five"></div>
                <div className="shape-blob six"></div>
                <div className="shape-blob seven"></div>
                <div className="shape-blob eight"></div>
                <div className="shape-blob nine"></div>
                <div className="shape-blob ten"></div>
            </div>
        </div>
    );
}

export default App;